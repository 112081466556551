if($('.p-message__thumbnail').length){
	$('.p-message__thumbnail').slick({
		accessibility: false,
		fade: true,
		autoplay: true,
		infinite: true,
		dots: false,
		arrows: false,
		autoplaySpeed: 3000,
		speed: 3000,
	});
};
