'use strict';

import "@babel/polyfill";
import 'slick-carousel';
import { ua } from './view/_ua';
import { height } from './view/_height';
import { header } from './view/_header';
// import { thumbnail } from './view/_thumbnail';
// import { heading } from './view/_heading';
import { scroll } from './view/_scroll';
import { home } from './view/_home';
import { accordion } from './view/_accordion';
// import { map } from './view/_map';
import { member } from './view/_member';
import { service } from './view/_service';
import { news } from './view/_news';
