import gsap from 'gsap';
import scrollMagic from 'scrollmagic'
import { ScrollTrigger, ScrollToPlugin, TweenMax } from 'gsap/all';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

// common
let _EffectH = 100;
$(window).on('scroll load', function() {
	let _scTop = $(this).scrollTop();
	let _scBottom = _scTop + $(this).height();
	let _effectPos = _scBottom - _EffectH;
	$('[data-scroll]').each( function() {
		let _thisPos = $(this).offset().top;
		if ( _thisPos < _effectPos ) {
			$.when(
				$(this).addClass('is-show')
			).done(function() {
				$(this).delay(500).queue(function(){
					$(this).addClass('is-done')
				})
			});
		}
	});
});

// ticker
gsap.utils.toArray('.js-ticker').forEach((tickerContent, index) => {
	const _w = tickerContent.querySelector('.c-ticker__word');
	const [_x, _xEnd] = ['100%', (_w.scrollWidth - tickerContent.offsetWidth) * -1];
	gsap.fromTo(_w, {  _x  }, {
		x: _xEnd,
		scrollTrigger: {
			trigger: tickerContent,
			scrub: 0.5
		}
	});
});
