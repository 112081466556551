if($('.p-homePlan__list').length){
	$('.p-homePlan__list').slick({
		accessibility: false,
		arrows: true,
		infinite: true,
		appendArrows: $('.p-homePlan__btns'),
		prevArrow: '<button class="slide-arrow prev-arrow"></button>',
		nextArrow: '<button class="slide-arrow next-arrow"></button>',
		centerMode: true,
		variableWidth: true,
		centerPadding: '15px',
		responsive: [{
			breakpoint: 769,
			settings: {
				slidesToShow: 1,
				variableWidth: false,
				centerPadding: '0',
			}
		}]
	});
};
